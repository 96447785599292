import React from "react";
import {
  FaCheckCircle,
  FaInfoCircle,
  FaRegCircle,
  FaTimesCircle,
} from "react-icons/fa";

const StatusBoxStudent = ({
  status,
  status_program_mapping,
  is_accepted_regular_class,
  program_name,
}) => {
  const statusIconBox = (status) => {
    if (status === "diterima") {
      return <FaCheckCircle size={40} className="mx-auto mb-4" />;
    } else if (status === "tidak diterima") {
      if ( program_name !== "Reguler" && is_accepted_regular_class && status_program_mapping === 0) {
        return <FaCheckCircle size={40} className="mx-auto mb-4" />;
      } else {
        return <FaTimesCircle size={40} className="mx-auto mb-4" />;
      }
    } else if (status === "mengundurkan diri") {
      return <FaInfoCircle size={40} className="mx-auto mb-4" />;
    } else {
      return <FaRegCircle size={40} className="mx-auto mb-4" />;
    }
  };
  return <div>{statusIconBox(status)}</div>;
};

export default StatusBoxStudent;
