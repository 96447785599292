import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "../Components/Button";
import TopBar from "../Components/TopBar";
import { authenticated, loadingscreen } from "../Store";
import { get } from "../Utils/Axios";
import BillDefault from "./Components/BillDefault";
import Footer from "../Components/Footer";
import { FaArrowCircleDown } from "react-icons/fa";
import { getPdf } from "../Utils/BillFunct";
import PaymentCheckout from "./Components/PaymentCheckout";

const BillContainer = () => {
  const auth = useRecoilValue(authenticated);
  const setLoading = useSetRecoilState(loadingscreen);
  const [bill, setBill] = useState(false);
  const [modal, setModal] = useState(false);
  const { id } = useParams();
  const to_route = useNavigate();
  const getBill = async () => {
    try {
      setLoading(true);
      const response = await get(`/students/bill/${id}`);
      if (response.status === 200) {
        setBill(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      to_route("/notfound");
      // console.log(error.response.data);
    }
  };

  useEffect(() => {
    if (bill === false) getBill();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <div className="bg-slate-300 min-h-screen flex flex-col justify-between relative">
      <TopBar />
      <div className="px-4 block mt-8">
        {bill ? (
          <Fragment>
            <div className="bg-slate-100">
              <BillDefault
                bill={bill.data}
                profile={bill.profile}
                billing={bill.email}
              />
            </div>
            <div className="flex flex-col-reverse md:flex-row gap-4 justify-between bg-slate-100 p-3 mt-8 rounded sticky bottom-0 z-50">
              <Button
                color="bg-orange-400"
                onClick={() => to_route("/dashboards")}
              >
                back
              </Button>

              <Button onClick={() => getPdf(bill)}>download</Button>
              {bill.data.invoice && bill.data.status === "menunggu" ? (
                <Button
                  color="bg-green-500 relative"
                  onClick={() => setModal(!modal)}
                >
                  <FaArrowCircleDown
                    size={35}
                    className="animate__animated animate__bounce animate__infinite mb-4 mx-auto absolute bottom-8 m-auto left-0 right-0 text-green-600"
                  />
                  bayar
                </Button>
              ) : (
                <Fragment>
                  {bill.data.method === "online" &&
                  bill.data.status === "menunggu" &&
                  auth.user.btn_checkout ? (
                    <Button
                      color="bg-purple-500"
                      disabled={auth.user.btn_checkout === 0 ? true : false}
                      onClick={() => setModal(!modal)}
                    >
                      checkout
                    </Button>
                  ) : (
                    ""
                  )}
                </Fragment>
              )}
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </div>
      {modal ? (
        <PaymentCheckout
          modal={modal}
          setModal={setModal}
          bill={bill?.data}
          getBill={() => getBill()}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
};

export default BillContainer;
