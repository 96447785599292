import React, { Fragment } from "react";

const Select = (props) => {
  const {
    name,
    label,
    description = "",
    options = [],
    errors,
    default_label = "--Pilih--",
    disableDefault = false,
    lowercaseValues = true,
    onChange,
    value,
  } = props;

  return (
    <Fragment>
      <label htmlFor={name} className="capitalize block mb-1 mt-4">
        {label}
      </label>
      <span className="capitalize block mb-4 text-sm">{description}</span>
      <select
        className="p-2 w-full rounded-full shadow-md capitalize bg-white"
        placeholder={label}
        onChange={onChange}
        value={value}
        name={name}
      >
        {!disableDefault && <option value="">{default_label}</option>}
        {options.map((option) => {
          const value = typeof option === "object" ? option.id : option;
          const optionLabel = typeof option === "object" ? option.name : option;
          const lowerCaseValue = lowercaseValues
            ? String(value).toLowerCase()
            : value;
          return (
            <option key={lowerCaseValue} value={lowerCaseValue}>
              {optionLabel}
            </option>
          );
        })}
      </select>
      {errors && errors[name] && (
        <div className="w-full bg-red-500 text-white py-2 px-4 mt-3">
          {errors[name]}
        </div>
      )}
    </Fragment>
  );
};

export default Select;
