import "./App.css";
import "animate.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoginContainer from "./Logins/LoginContainer";
import DashboardContainer from "./Dashboards/DashboardContainer";
import Authenticated from "./Middlewares/Authenticated";
import { Fragment, useEffect } from "react";
import LoadingScreen from "./Components/LoadingScreen";
import BiodataContainer from "./Biodatas/BiodataContainer";
import BillContainer from "./Bills/BillContainer";
import ExamContainer from "./Exams/ExamContainer";
import QuranContainer from "./Qurans/QuranContainer";
import ReContainer from "./Reregisters/ReContainer";
import AnnouncementContainer from "./Announcements/AnnouncementContainer";
import QuestionContainer from "./Exams/QuestionContainer";
import ProfileContainer from "./Profiles/ProfileContainer";
import ForgotContainer from "./Logins/ForgotContainer";
import ResetContainer from "./Logins/ResetContainer";
import DiscountContainer from "./Discounts/DiscountContainer";
import DiscountFormContainer from "./Discounts/DiscountFormContainer";
import NotFound404 from "./Errors/Notfound404";
// import { useRecoilValue } from "recoil";
// import { authenticated } from "./Store";
import VerifyContainer from "./Verifies/VerifyContainer";
import ProgramMappingContainer from "./ProgramMappings/ProgramMappingContainer";
// import ExpiredDoku from "./Bills/ExpiredDoku";
function App() {
  // const auth = useRecoilValue(authenticated);
  const location = useLocation();
  // const to_route = useNavigate();
  useEffect(() => {
    if (location.pathname !== "/") {
      sessionStorage.setItem("lastpath", location.pathname);
    }
  }, [location]);

  // useEffect(() => {
  //   if (auth.user.discount_application) {
  //     to_route("/discounts/apply");
  //   }
  //   // eslint-disable-next-line
  // }, [auth.user.discount_application]);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<LoginContainer />} />
        <Route path="/:token/verify" element={<VerifyContainer />} />
        <Route path="/forgot" element={<ForgotContainer />} />
        <Route path="/:token/reset" element={<ResetContainer />} />
        <Route
          path="/dashboards"
          element={
            <Authenticated>
              <DashboardContainer />
            </Authenticated>
          }
        />
        <Route
          path="/biodatas"
          element={
            <Authenticated>
              <BiodataContainer />
            </Authenticated>
          }
        />
        <Route
          path="/bills/:id"
          element={
            <Authenticated>
              <BillContainer />
            </Authenticated>
          }
        />
        {/* <Route
          path="/bills/:id/expired"
          element={
            <Authenticated>
              <ExpiredDoku />
            </Authenticated>
          }
        /> */}
        <Route
          path="/students/exam"
          element={
            <Authenticated>
              <ExamContainer />
            </Authenticated>
          }
        />
        <Route
          path="/students/quran"
          element={
            <Authenticated>
              <QuranContainer />
            </Authenticated>
          }
        />
        <Route
          path="/students/program-mapping"
          element={
            <Authenticated>
              <ProgramMappingContainer />
            </Authenticated>
          }
        />
        <Route
          path="/students/re/register"
          element={
            <Authenticated>
              <ReContainer />
            </Authenticated>
          }
        />
        <Route
          path="/students/announcement/:id"
          element={
            <Authenticated>
              <AnnouncementContainer />
            </Authenticated>
          }
        />
        <Route
          path="/students/question"
          element={
            <Authenticated>
              <QuestionContainer />
            </Authenticated>
          }
        />
        <Route
          path="/students/profile"
          element={
            <Authenticated>
              <ProfileContainer />
            </Authenticated>
          }
        />
        {/* <Route
          path="/discounts/apply"
          element={
            <Authenticated>
              <DiscountContainer />
            </Authenticated>
          }
        />
        <Route
          path="/students/discount"
          element={
            <Authenticated>
              <DiscountFormContainer />
            </Authenticated>
          }
        /> */}
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      <LoadingScreen />
    </Fragment>
  );
}

export default App;
