import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import TopBar from "../Components/TopBar";
import { authenticated, loadingscreen, scholarships } from "../Store";
import { get, put } from "../Utils/Axios";
import StudentBiodataForm from "./Components/StudentBiodataForm";
import StudentScholarshipForm from "./Components/StudentScholarshipForm";

const BiodataContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const [scholarship, setScholarship] = useRecoilState(scholarships);
  const setLoading = useSetRecoilState(loadingscreen);
  const [records, setRecords] = useState(false);
  const [programs, setPrograms] = useState(false);
  const [errors, setErrors] = useState([]);
  const authUserChange = () => {
    const change = { ...auth.user };
    change.name = records.name;
    const changeAuth = { ...auth };
    changeAuth.user = change;
    setAuth(changeAuth);
    // console.log(records);
    return;
  };
  const getBiodata = async () => {
    try {
      setLoading(true);
      const biodata = await get("/students/biodata");
      if (biodata.status === 200) {
        setRecords(biodata.data);
        if (biodata.data.scholarship) {
          setScholarship(biodata.data.scholarship);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // console.log(error.data);
    }
  };
  const getProgram = async () => {
    try {
      const program = await get("/programs");
      if (program.status === 200) {
        setPrograms(program.data);
      }
      
    } catch (error) {
      // console.log(error.data);
    }
  }
  const updateBiodata = async () => {
    try {
      setLoading(true);
      let data = records;
      if (scholarship.name !== " ") {
        data = {
          ...records,
          scholarship,
        };
      }

      let biodata = await put(`/students/biodata/update`, data);
      if (biodata.status === 200) {
        authUserChange();
        setErrors([]);
        Swal.fire({
          icon: "success",
          title: "Succcess",
          text: biodata.data.message,
        });
      }
      setLoading(false);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "Periksa form biodata kembali",
      });
    }
  };
  const handleRecords = (el) => {
    const change = { ...records };
    const input = el.target;
    change[input.name] = input.value;
    setRecords(change);
    // console.log(recordsRef.current);
  };
  const handleRecordsOnlyNumber = (el) => {
    // console.log(typeof recordsRef.current.nisn);
    const change = { ...records };
    const input = el.target;
    const value = input.value;
    let thenum = value.replace(/\D/g, "");
    change[input.name] = thenum;
    setRecords(change);
    // console.log(recordsRef.current);
  };

  useEffect(() => {
    if (records === false) getBiodata();
    return () => {};
    // eslint-disable-next-line
  }, [auth.name]);

  useEffect(() => {
    if (programs === false) getProgram();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-slate-300 min-h-screen flex flex-col justify-between">
      <TopBar />
      <div className="px-4 block mt-8">
        <span className="p-3 bg-green-400 font-bold rounded">
          NO PENDAFTARAN {auth.user.no_register}
        </span>
      </div>
      <div className="px-4 block mt-4">
        {errors.message ? (
          <div className="p-3 bg-red-400 font-bold rounded text-white">
            {errors.message}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="p-4">
        {records.name ? (
          <Fragment>
            <StudentBiodataForm
              records={records}
              programs={programs}
              handleRecords={(e) => handleRecords(e)}
              handleRecordsOnlyNumber={(e) => handleRecordsOnlyNumber(e)}
              errors={errors}
            />
            {auth.user.scholarship_form ? (
              <StudentScholarshipForm errors={errors} />
            ) : (
              ""
            )}
          </Fragment>
        ) : (
          ""
        )}
        <div className="flex justify-between bg-slate-100 p-3 mt-8 rounded">
          <Link to="/dashboards">
            <Button color="bg-orange-400">back</Button>
          </Link>
          <Button onClick={() => updateBiodata()}>update biodata</Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BiodataContainer;
