import React, { Fragment } from "react";

const NotifyBox = ({ message }) => {
  return (
    <Fragment>
      {message && !message.button ? (
        <div className="w-full mt-6 px-4 py-6 bg-gray-100 font-bold rounded-2xl capitalize shadow-md">
          <h2 className="block text-xl font-bold mb-3 text-[#000066]">
            Pengumuman
          </h2>
          <p>{message}</p>
        </div>
      ) : (
        ""
      )}
      {message.text && message.button ? (
        <div className="w-full mt-6 px-4 py-6 bg-gray-100 font-bold rounded-2xl shadow-md">
          <h2 className="block text-xl font-bold mb-3 text-[#000066]">
            Pengumuman
          </h2>
          <div className="flex flex-col md:flex-row justify-center gap-6 md:gap-2">
            <p className="basis-10/12">{message.text}</p>
            <div className="py-2 text-right">
              <a
                href={process.env.REACT_APP_GROUP_WA}
                target={"blank"}
                rel="noreferrer"
                className="bg-green-300 block text-center uppercase py-4 px-6 rounded-full text-sm"
              >
                group whatsapp
              </a>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default NotifyBox;
