import React from "react";
import Select from "../../Components/Select";
import ScholarshipAcademicForm from "./ScholarshipAcademicForm";
import ScholarshipBasketForm from "./ScholarshipBasketForm";
import ScholarshipTahfidzForm from "./ScholarshipTahfidzForm";
import { useRecoilState } from "recoil";
import { scholarships } from "../../Store";

const StudentScholarshipForm = () => {
  const [scholarship, setScholarship] = useRecoilState(scholarships);
  const scholarshipForm = () => {
    if (scholarship.name === "beasiswa akademik") {
      return <ScholarshipAcademicForm type={scholarship.name} />;
    } else if (scholarship.name === "beasiswa basket") {
      return <ScholarshipBasketForm type={scholarship.name} />;
    } else if (scholarship.name === "beasiswa tahfidz") {
      return <ScholarshipTahfidzForm type={scholarship.name} />;
    } else {
      return;
    }
  };
  return (
    <div className="bg-slate-100 mt-8">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        beasiswa siswa
      </h2>
      <div className="p-3 pb-6">
        <Select
          name="interest"
          type="text"
          label="Jenis Beasiswa"
          description="Persyaratan dan ketentuan berlaku"
          default_label={"Non Beasiswa"}
          value={scholarship.name}
          options={["Beasiswa Akademik", "Beasiswa Basket", "Beasiswa Tahfidz"]}
          onChange={(el) => setScholarship({ name: el.target.value })}
          errors={{}}
        />
        <div className="w-full mt-6">{scholarshipForm()}</div>
      </div>
    </div>
  );
};

export default StudentScholarshipForm;
