import React from "react";

const BillDefault = ({ bill, profile, billing }) => {
  return (
    <div>
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        tagihan {bill?.name}
      </h2>
      <div className="p-3">
        <div className="block">
          <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
            <div>
              <div
                className={`uppercase font-bold py-2 px-4 rounded ${
                  bill.status === "lunas" ? "bg-green-400" : "bg-gray-400"
                }`}
              >
                {bill.status}
              </div>
            </div>
            <div className="md:w-1/3 text-right">
              {profile?.name}
              <br />
              {billing?.billing_email}
              <br />
              {profile?.address}
              <br />
              {profile?.phone}
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="flex justify-between items-center">
            <div>
              <strong>{bill?.id}</strong>
              <br />
              Tanggal {bill?.created_at}
              <br />
              {bill.invoice && bill.status === "menunggu" ? (
                <>
                  Batas Waktu Bayar {bill?.invoice?.expired}
                  <br />
                </>
              ) : (
                ""
              )}
              {bill.status === "lunas" ? (
                <>
                  Tanggal Bayar {bill?.paid_at}
                  <br />
                </>
              ) : (
                ""
              )}
            </div>
            
          </div>
        </div>
        <div className="block p-2 mt-8 overflow-x-auto">
          <table>
            <tbody>
              <tr>
                <td className="font-bold">Tagihan Ke</td>
                <td></td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>&nbsp;:</td>
                <td>&nbsp;{bill?.student?.customer.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>&nbsp;:</td>
                <td>&nbsp;{bill?.student?.customer.phone}</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>&nbsp;:</td>
                <td>&nbsp;{bill?.student?.customer.email}</td>
              </tr>
              <tr>
                <td>Alamat</td>
                <td>&nbsp;:</td>
                <td>&nbsp;{bill?.student?.customer.address}</td>
              </tr>
              <tr>
                <td>No Pendaftaran</td>
                <td>&nbsp;:</td>
                <td>&nbsp;{bill?.student?.customer.no_register}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="block p-2 mt-8">
          <table className="border-collapse border border-slate-500 table-auto w-full">
            <thead>
              <tr className="bg-slate-700 text-white">
                <th className="border border-slate-600 p-3">NAME</th>
                <th className="border border-slate-600 p-3">NOMINAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-slate-600 text-center p-1">
                  {bill?.name}
                </td>
                <td className="border border-slate-600 text-center p-1">
                  {bill?.price}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="block p-2 mt-8">
          <div className="flex justify-end">
            <table>
              <tbody>
                <tr>
                  <td className="py-1">Admin</td>
                  <td>&nbsp;</td>
                  <td>{bill?.admin_price}</td>
                </tr>
                <tr className="font-bold">
                  <td className="py-1">Total</td>
                  <td>&nbsp;</td>
                  <td>{bill?.amount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillDefault;
